export const disallowedUrls = [
    "/xcxxxx/following",
    "/xxxf/albums",
    "/saveporn/history",
    "/album/xxx/ok-band",
    "/xxxxxxxzz",
    "/artist/videosex",
    "/album/saxxx/dino-sor",
    "/xxxxxxxzz/artists",
    "/xxxxxxxzz/pinned",
    "/wexxx/albums",
    "/xxxxxxxzz/tracks",
    "/album/3sex/indochine",
    "/xxxaxxx",
    "/album/scp-xxx/kevin-macleod",
    "/album/xx/the-xx",
    "/wexxx/tracks",
    "/dannyxxx",
    "/dannyxxx/history",
    "/xxx/followers",
    "/xcxxxx/albums",
    "/xcxxxx/artists",
    "/album/erotic-hardcore-love-massage/moaning-eve",
    "/album/nigger-gangster-vs-moonman-notorious-kkk-gangsta-rap-battle/radiokepala/reviews",
    "/album/www-fuckme-com/ayesha-erotica",
    "/album/doggy-love-asmr/sexy-eva-love-asmr",
    "/album/love-hungry-girl-asmr/sexy-eva-love-asmr",
    "/masturbateandchill/albums",
    "/album/sex-angel/esex",
    "/analcorpse/review/album/heartbreak-station/triple777",
    "/album/sexy-girls-moaning-whimpering-asmr/sexy-girls-whimpering-asmr",
    "/album/sexy-bedroom-vibes-romantic-chillout-songs-for-lovers-2019/minimal-lounge",
    "/wexxx",
    "/xxx123",
    "/album/xxx-lp/yan-cook",
    "/mimixxx",
    "/xxxf/history",
    "/artist/seb-xxx",
    "/album/love-guess-who-xxooxx/autre-ne-veut",
    "/sixxxx",
    "/album/sukoon/xxx",
    "/album/overflow-xxxx/zytokine",
    "/pepper0/history",
    "/album/sos-sex-on-sight-feat-usher/victoria-monet",
    "/xvxvxvx",
    "/album/blah-2/nomi-xd",
    "/xaxc/tracks",
    "/katixxx",
    "/artist/moaning-eve",
    "/album/icinde-bir-sizi-kalir/ugur-isilak",
    "/xxax54",
    "/album/american-super-sexy-girl/chief-kooffreh",
    "/casparoonie",
    "/album/inca-sex/corpus",
    "/onlybrooke",
    "/album/godsmack/godsmack",
    "/nmwv/review/album/xxyyxx/xxyyxx",
    "/xvxvxvx/pinned",
    "/album/sex-on-the-beach/mami/ratings",
    "/almaxxx/albums",
    "/xaxc/history",
    "/album/silakbo/cup-of-joe",
    "/album/stepmom-love-asmr/moaning-eve",
    "/artist/the-xx",
    "/album/333/matue",
    "/artist/moaning-eve/releases",
    "/sexdollxx",
    "/ssxxcc/pinned",
    "/zzzxzxx/history",
    "/topbet888",
    "/album/homixide-lifestyle-2/homixide-gang",
    "/sexov",
    "/album/sex-buzz/medu",
    "/sexbas_/history",
    "/artist/rule-34"
]
