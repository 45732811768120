import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { ogImageUrl, twitterImageUrl } from '../../config'

import { disallowedUrls } from '../../disallowed-urls'

export default function DefaultHelmet({ 
    title="Musicboard - Ratings, Reviews, and Lists. Explore New Music.",
    description="Musicboard is the world's #1 source for music reviews, ratings, lists and information. Find reviews for the latest music and join a community of music lovers.",
    canonical="https://musicboard.app",
    ogType="website",
    ogImage=ogImageUrl,
    ogImageWidth=2400,
    ogImageHeight=1440,
    ogImageAlt="Musicboard",
    twitterImage=twitterImageUrl,
    twitterImageWidth=1428,
    twitterImageHeight=824,
    labelOne=null,
	labelTwo=null,
	deepLink="musicboard://",
    headingOne,
    headingTwo,
    skipCanonical,
    skipDescription,
}) {
    //General
    const location = useLocation()

    //Update heading
    useEffect(() => {
        if (headingOne && headingTwo) {
            const h1 = document.getElementById("heading-one")
            const h2 = document.getElementById("heading-two")
            if (h1 && h2) {
                h1.innerText = headingOne
                h2.innerText = headingTwo
            } else {
                const div = document.getElementById("heading-div")
                if (div) {
                    div.innerHTML = `
        <h1 class="heading-text" id="heading-one">${headingOne}</h1>
        <h2 class="heading-text" id="heading-two">${headingTwo}</h2>
                    `
                }
            }
        }
    }, [headingOne, headingTwo])
    
    useEffect(() => {
        return (() => {
            const h1 = document.getElementById("heading-one")
            const h2 = document.getElementById("heading-two")
            if (h1 && h2) {
                //Delete the elements
                h1.remove()
                h2.remove()
            }
        })
    }, [])

    return (
        <Helmet>
            {disallowedUrls.includes(location.pathname.replace(/\/$/, '')) &&
                <meta name="robots" content="noindex" />
            }

            <meta 
            name="apple-itunes-app" 
            content={`app-id=1503544789, app-argument=${deepLink}`}
            />
            
            <meta name="author" content="Musicboard" />
            
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="Musicboard" />

            <title>{title}</title>

            {!skipDescription &&
                <meta 
                name="description"
                content={description}
                />
            }

            {!skipCanonical &&
                <link 
                rel="canonical" 
                href={canonical}
                />
            }
            
            <meta 
            property="og:title" 
            content={title}
            />

            <meta 
            property="og:url" 
            content={canonical}
            />
            
            <meta 
            property="og:description"  
            content={description}
            />

            <meta 
            property="og:image"
            content={ogImage}
            />
                    
            <meta 
            property="og:image:width" 
            content={ogImageWidth}
            />
                
            <meta 
            property="og:image:height" 
            content={ogImageHeight}
            />
            
            <meta 
            property="og:image:alt" 
            content={ogImageAlt}
            />
                    
            <meta 
            property="og:type" 
            content={ogType}
            />
                    
            <meta 
            name="twitter:card" 
            content={"summary_large_image"}
            />
                    
            <meta 
            name="twitter:site" 
            content="@musicboardapp" 
            />
                    
            <meta 
            name="twitter:title" 
            content={title}
            />

            <meta 
            name="twitter:url" 
            content={canonical}
            />
                
            <meta 
            name="twitter:description"
            content={description}
            />
                
            <meta 
            name="twitter:image" 
            content={twitterImage}
            />

            <meta 
            name="twitter:image:width" 
            content={twitterImageWidth}
            />
                
            <meta 
            name="twitter:image:height" 
            content={twitterImageHeight}
            />

            {labelOne &&
                <meta 
                name="twitter:label1" 
                content={labelOne.label}
                />
            }

            {labelOne &&
                <meta 
                name="twitter:data1" 
                content={labelOne.data}
                />
            }

            {labelTwo &&
                <meta 
                name="twitter:label2" 
                content={labelTwo.label}
                />
            }

            {labelTwo &&
                <meta 
                name="twitter:data2" 
                content={labelTwo.data}
                />
            }
        </Helmet>
    )
}
